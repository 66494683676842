import api from '@/base/utils/request'

export const getSmsList = (data) => {
  return api({
    url: '/admin/admin/sms_message/index',
    method: 'post',
    data,
  })
}

export const changeStatus = (id, status) => {
  return api({
    url: '/admin/admin/sms_message/changeStatus',
    method: 'post',
    data: { id, status },
    notCancel: true,
  })
}

export const changeAllStatus = (status, tab) => {
  return api({
    url: '/admin/admin/sms_message/changeAllStatus',
    method: 'post',
    data: { status, tab },
    notCancel: true,
  })
}
